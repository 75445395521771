/**
 * スムーススクロール
 *
 * require: GSAP & Scroll Plugin
 * https://greensock.com/
 * https://greensock.com/ScrollToPlugin
 *
 */
import { gsap, Circ } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import UAParser from "ua-parser-js";

gsap.registerPlugin(ScrollToPlugin);

class SmoothScroll {
  constructor(duration = 1, ease = Circ.easeInOut) {
    this.duration = duration;
    this.ease = ease;
  }

  _isMobile() {
    return (
      UAParser().device.type === "mobile" || UAParser().device.type === "tablet"
    );
  }

  _getOffset() {
    const header = document.querySelector(".header");
    return header ? header.getBoundingClientRect().height : 0;
  }

  to(y, offsetY) {
    gsap.to(window, {
      scrollTo: {
        autoKill: !this._isMobile(),
        offsetY: offsetY || y === "#top" ? 0 : this._getOffset(),
        y: y === "#top" ? 0 : y || 0,
      },
      duration: this.duration,
      ease: this.ease,
    });
  }
}

export default SmoothScroll;
