/**
 * グローバルナビコントローラー
 */

import button from "@/util/button";
import { mq } from "@/common/config";

class GlobalNav {
  constructor(
    menuActiveClass = "js-is-menu-open",
    closeElement = `js-is-manu-close, .gnav-overlay, .header__brand`
  ) {
    this.menuActiveClass = menuActiveClass;
    this.toggleMenuButton = document.querySelectorAll(".js-menu-toggle");
    this.gnavLink = document.querySelectorAll(closeElement);
    this.addEvent();
  }

  addEvent() {
    button(this.toggleMenuButton, () => this.menuToggle());
    button(this.gnavLink, () => this.removeActiveClass(this.menuActiveClass));
    window.matchMedia(mq.lg).addListener(() => this.reset());
  }

  /**
   * body クラス付け外し
   */
  toggleClass(activeClass) {
    if (document.body.classList.contains(activeClass)) {
      document.body.classList.remove(activeClass);
    } else {
      document.body.classList.add(activeClass);
    }
  }

  /**
   * bodyからアクティブクラス削除
   */
  removeActiveClass(targetClass) {
    document.body.classList.remove(this.menuActiveClass);
    document.body.classList.remove(targetClass);
  }

  /**
   * 状態リセット
   */
  reset() {
    this.removeActiveClass(this.menuActiveClass);
  }

  /**
   * SPメニュー開閉
   */
  menuToggle() {
    this.toggleClass(this.menuActiveClass);
  }
}

/**
 * 初期化
 */
export default GlobalNav;
