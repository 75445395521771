/**
 * async で DOMContentLoadedが発火しない対策
 */
export default handler => {
  if (document.readyState !== "loading") {
    window.setTimeout(handler.bind(this), 0);
    return this;
  }
  document.addEventListener("DOMContentLoaded", handler.bind(this), false);
  return this;
};
