import Glide from "@glidejs/glide";

class ThumbnailSlider {
  constructor(
    sliderLeft = ".thumbnail-slider .is-slider-left",
    sliderRight = ".thumbnail-slider .is-slider-right"
  ) {
    this.slider = {};
    this.slider.left = document.querySelector(sliderLeft);
    this.slider.right = document.querySelector(sliderRight);

    if (!this.slider.left || !this.slider.right) return;

    this.option = {
      type: "carousel",
      focusAt: "center",
      animationDuration: 800,
      autoplay: 3000,
      gap: 0,
      perView: 1,
      startAt: 0,
    };

    this.glideLeft = new Glide(this.slider.left, this.option);
    this.glideRight = new Glide(this.slider.right, this.option);

    this.addEvent();
  }

  addEvent() {
    this.glideLeft
      .on("run", event => this.glideRight.go(event.direction))
      .mount();

    this.glideRight
      .on("run", event => this.glideLeft.go(event.direction))
      .mount();
  }
}

export default ThumbnailSlider;
