import "intersection-observer";
import lozad from "lozad";
import asyncReady from "@/util/asyncReady";
import CircleSlider from "@/common/CircleSlider";
import Gnav from "@/common/Gnav";
import GoTop from "@/common/GoTop";
import HeroSlider from "@/common/HeroSlider";
import Modal from "@/common/Modal";
import OnLoad from "@/common/OnLoad";
import ScrollTrigger from "@/common/ScrollTrigger";
import ThumbnailSlider from "@/common/ThumbnailSlider";
import Sticky from "@/common/Sticky";

const common = () => {
  new OnLoad();
  new Gnav();
  new GoTop();
  new Modal();
  new ScrollTrigger();
  new CircleSlider();
  new HeroSlider();
  new ThumbnailSlider();
  new Sticky();

  // lazyload
  // https://github.com/ApoorvSaxena/lozad.js
  const observer = lozad();
  observer.observe();
};

asyncReady(common);
