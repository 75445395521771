/**
 * Modal
 */

import closest from "@/util/closest";
import onClick from "@/util/onClick";
import { mq } from "@/common/config";

/**
 * モ-ダル開閉クラス
 *
 * モーダル要素: data-modal
 * 表示ボタン: data-modal-trigger="{対象モーダルのIDセレクタ}"
 * 閉じるボタン: data-modal-close
 *
 * @class Modal
 */
class Modal {
  constructor() {
    this.bodyModalClass = "js-is-modal";
    this.isModalVisible = "js-is-modal-visible";
    this.menuActiveClass = "js-is-menu-open";
    this.modal = "[data-modal]";
    this.modalDialog = "[data-modal-dialog]";
    this.modalClose = "[data-modal-close]";
    this.modalOpen = "[data-modal-trigger]";

    this.addEvent();
  }

  /**
   * イベント登録
   *
   * @memberof Modal
   */
  addEvent() {
    if (
      !document.querySelectorAll(this.modal).length ||
      !document.querySelectorAll(this.modalOpen).length
    ) {
      return;
    }

    this.removeClass();

    onClick(this.modalClose, () => this.removeClass());
    onClick(this.modalOpen, event => this.toggleClass(event));

    document.body.addEventListener("click", event => this.outerClick(event));

    window.matchMedia(mq.lg).addListener(() => this.removeClass());
  }
  /**
   * 全モーダル非表示
   *
   * @memberof Modal
   */
  removeClass() {
    const visible = document.querySelectorAll(`.${this.isModalVisible}`);

    document.body.classList.remove(this.bodyModalClass);

    if (!visible.length) return;

    visible.forEach(el => el.classList.remove(this.isModalVisible));
  }

  /**
   * 対象セレクタのモーダルを表示
   *
   * @memberof Modal
   */
  addClass(target) {
    this.removeClass();

    target.classList.add(this.isModalVisible);
    document.body.classList.remove(this.menuActiveClass);
    document.body.classList.add(this.bodyModalClass);
  }

  /**
   * モーダルをトグル
   *
   * @param {object} event
   * @memberof Modal
   */
  toggleClass(event) {
    const targetSelector = event.target.getAttribute("data-modal-trigger");
    const target = document.querySelector(targetSelector);

    if (!target) return;

    if (!target.classList.contains(this.isModalVisible)) {
      this.addClass(target);
    } else {
      this.removeClass();
    }
  }

  /**
   * モーダル要素外をクリックした場合に閉じる
   *
   * @param {object} event
   * @returns
   * @memberof Modal
   */
  outerClick(event) {
    const isClose =
      document.body.classList.contains(this.bodyModalClass) &&
      !closest(event.target, this.modalDialog) &&
      !closest(event.target, this.modalOpen);

    if (!isClose) return;

    this.removeClass();
  }
}
export default Modal;
