import Glide from "@glidejs/glide";

/**
 * ホームページ: ゼミサークルスライダー
 *
 * @class CircleSlider
 */
class CircleSlider {
  constructor(slider = ".circle-slider .glide") {
    this.slider = document.querySelector(slider);

    if (!this.slider) return;

    this.glide = new Glide(this.slider, {
      type: "carousel",
      focusAt: "center",
      autoplay: 5000,
      animationDuration: 1000,
      gap: 60,
      perView: 4,
      startAt: 0,
      peek: 60,
      breakpoints: {
        576: { perView: 1, gap: 30, peek: 45 },
        768: { perView: 2, gap: 30, peek: 45 },
        1200: { perView: 3, gap: 30, peek: 45 },
      },

      // 自動スクロール用
      // animationDuration: 10000,
      // animationTimingFunc: "linear",
      // autoplay: 0.1,
    });

    this.addEvent();
  }

  addEvent() {
    this.glide.mount();
  }
}

export default CircleSlider;
