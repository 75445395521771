/**
 * _.throttle
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_throttle
 *
 * @param {Function} func
 * @param {Number} timeFrame
 */
export default (func, timeFrame) => {
  let lastTime = 0;
  return () => {
    const now = new Date();
    if (now - lastTime >= timeFrame) {
      func();
      lastTime = now;
    }
  };
};
