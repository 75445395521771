class OnLoad {
  constructor() {
    this.addEvent();
  }

  addEvent() {
    window.addEventListener("load", () => this.loaded());
  }

  loaded() {
    document.body.classList.remove("preload");
  }
}

export default OnLoad;
