import { gsap } from "gsap";
import button from "@/util/button";
import SmooothScroll from "@/common/SmoothScroll";

class GoTop {
  constructor(
    goto = ".go-top",
    hash = 'a[href^="#"]:not([href="#"]):not([href="#0"])'
  ) {
    this.scr = new SmooothScroll();
    this.gotoBtn = document.querySelectorAll(goto);
    this.hash = document.querySelectorAll(hash);
    this.addEvent();
  }

  addEvent() {
    this.tweenVisible(this.gotoBtn);
    button(this.gotoBtn, () => this.scr.to(0));
    button(this.hash, event => this.hasScroll(event));
    window.addEventListener("scroll", () => this.tweenVisible(this.gotoBtn));
  }

  isActive() {
    return (
      window.pageYOffset ||
      document.documentElement.scrollTop > window.innerHeight * 0.24
    );
  }

  tweenVisible(target) {
    const isActive = this.isActive();
    gsap.to(target, {
      duration: 0.6,
      autoAlpha: isActive ? 1 : 0,
      rotationY: isActive ? 0 : 180,
    });
  }

  hasScroll(event) {
    let node = event.target;
    while (node != document) {
      if (node.hash && document.querySelector(node.hash)) {
        event.preventDefault();
        this.scr.to(node.hash);
        break;
      }
      node = node.parentNode;
    }
  }
}

export default GoTop;
