import stickybits from "stickybits";
import { mq } from "@/common/config";

class Sticky {
  constructor(selector = "[data-sticky-item]") {
    if (!document.querySelector(selector)) {
      return;
    }
    this.el = document.querySelector(selector);
    this.header = document.querySelector("header.header");
    this.scrollPos = 0;
    this.distanceFromTop = 126;
    this.option = {
      useStickyClasses: true,
      stickyBitStickyOffset: this._getOffset(),
    };
    this.mq = window.matchMedia(mq.lg);
    this._init();
  }

  _getOffset() {
    return this.header.offsetHeight + 30;
  }

  _setStickElTop(directionIsDown) {
    this.isEnable =
      window.innerHeight - this.distanceFromTop < this.el.offsetHeight;

    this.el.style.top =
      directionIsDown && this.isEnable
        ? // allows scrolling to sticky bottom part when it's higher than viewport
          window.innerHeight - this.el.offsetHeight + "px"
        : this._getOffset() + "px";
  }

  _scrollListener() {
    if (!this.mq.matches) {
      this.scrollPos = 0;
      return;
    }
    const top = document.body.getBoundingClientRect().top;
    this._setStickElTop(top < this.scrollPos);
    this.scrollPos = top;
  }

  _toggleSticky() {
    if (this.mq.matches) {
      this.stickybits = stickybits(this.el, this.option);
    } else if (this.stickybits) {
      this.stickybits.cleanup();
    }
  }

  _init() {
    if (document.querySelector(".contents-detail-side-card")) {
      document.body.classList.add("is-sticky-enable");
    }
    this._scrollListener();
    this._toggleSticky();
    this.mq.addListener(() => this._toggleSticky());
    window.addEventListener("scroll", () => this._scrollListener());
  }
}

export default Sticky;
