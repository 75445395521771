/**
 * クリックイベント
 */
export default (selector, callback) => {
  document.addEventListener("click", event => {
    if (callback && typeof callback !== "function") {
      return;
    }
    let node = event.target;
    while (node != document) {
      if (node.matches(selector)) {
        callback(event);
        break;
      }
      node = node.parentNode;
    }
  });
};
