import ScrollOut from "scroll-out";

/**
 * Scroll add class
 * https://github.com/scroll-out/scroll-out
 *
 * @class ScrollTrigger
 */
class ScrollTrigger {
  constructor() {
    this.so = ScrollOut({});
  }
}

export default ScrollTrigger;
